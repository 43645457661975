import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import api from '../Api/Api';
import ModalClose from '@mui/joy/ModalClose';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { lang } from '../../lang/lang';

const EditSmenaModal = ({ editSmenaModal, setEditSmenaModal }) => {
  const [firtTime, setFirstTime] = useState(dayjs());
  const [lastTime, setLastTime] = useState(dayjs());
  const [selectedSmena, setSelectedSmena] = useState('');
  const language = localStorage.getItem('language');

  const handleClose = () => {
    setEditSmenaModal(false);
  };

  const handleSave = async () => {
    try {
      const response = await api.patch(
        `/Shift/${selectedSmena}/`,
        new URLSearchParams({
          'start_time': `${dayjs(firtTime).format('HH:mm:ss')}`,
          'end_time': `${dayjs(lastTime).format('HH:mm:ss')}`,
        }),
      );
  
      console.log(response.data);
      setEditSmenaModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleEdit = async (event) => {
    setSelectedSmena(event.target.value)
    try {
      const response = await api.get(`/Shift/${event.target.value}/`);
      const startTime = dayjs(response.data?.start_time, 'HH:mm:ss');
      const endTime = dayjs(response.data?.end_time, 'HH:mm:ss');
  
      setFirstTime(startTime);
      setLastTime(endTime);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div>
      <Modal
        open={editSmenaModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            textAlign: 'center',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
          {language === 'uzb' ? lang.uz.modal.editSmenaTime : lang.rus.modal.editSmenaTime}
          </Typography>
          <ModalClose onClick={handleClose} variant="plain" sx={{ m: 1 }} />
          <Box sx={{ marginTop: '20px' }} direction="row" spacing={2}>

          <FormControl sx={{ m: 1, minWidth: 120, margin: '10px 0' }} size="small">
              <InputLabel id="demo-select-small-label">{language === 'uzb' ? lang.uz.header.smena : lang.rus.header.smena}</InputLabel>
              <Select
                autoWidth
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Смена"
                value={selectedSmena}
                onChange={handleEdit}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{margin: '10px 0 20px 0'}} components={['TimePicker', 'TimePicker']}>
                <Box>
                  <TimePicker
                    autoWidth
                    slotProps={{ textField: { size: 'small' } }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                    }}
                    value={firtTime}
                    onChange={(newValue) => setFirstTime(newValue)}
                  />
                </Box>
                <Box>
                  <TimePicker
                    slotProps={{ textField: { size: 'small' } }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                    }}
                    value={lastTime}
                    onChange={(newValue) => setLastTime(newValue)}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>



            <Button
              sx={{ width: '100%' }}
              variant="contained"
              color="success"
              onClick={handleSave}
            >
              {language === 'uzb' ? lang.uz.header.addUsers : lang.rus.header.addUsers}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EditSmenaModal;
