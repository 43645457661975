import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, TablePagination, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from '../../Context/UserContext';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { lang } from '../../lang/lang';

const MonthComponent = () => {
  let currentMonth = dayjs();
  const { month, inDate, users, setChangeRows } = useContext(UserContext);
  const language = localStorage.getItem('language');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const inDates = inDate === undefined ? `${currentMonth.format('YYYY')}-${(currentMonth.month() + 1).toString().padStart(2, '0')}` : inDate
  const months = month === undefined ? dayjs(`${currentMonth.format('YYYY')}-${(currentMonth.month() + 1).toString()}`).daysInMonth() : month;
  const columns = [
    { id: 'id', label: '#', minWidth: 10 },
    { id: 'image', label: `${language === 'uzb' ? lang.uz.author.image : lang.rus.author.image}`, minWidth: 50 },
    { id: 'name', label: `${language === 'uzb' ? lang.uz.author.fullName : lang.rus.author.fullName}`, minWidth: 170 },
    ...Array.from({ length: months }, (_, i) => ({
      id: `day${i + 1}`,
      label: dayjs(`${inDates}-${i + 1}`, 'YYYY-MM-D').format('YYYY-MM-DD'),
      minWidth: 100,
    })),
    { id: 'total', label: `${language === 'uzb' ? lang.uz.author.allDelay : lang.rus.author.allDelay}`, minWidth: 120 },
  ];
  

  function createData(id, image, name, ...days) {
    const rowData = { id, image, name };
    days.forEach((day, index) => {
      rowData[`day${index + 1}`] = day || '';
    });
    rowData['total'] = days[days.length - 1];
    return rowData;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = users?.map((user, index) => {
    const { image, full_name, events } = user;
    const userEvents = [];

  let firstTimeOfTargetDate = '-:-';
  let lastTimeOfTargetDate = '-:-';
  let lateTimeOfTargetDate = '-:-';

  const event = {
    first_time: '-:-',
    last_time: '-:-',
    late_time: '-:-'
  }
  for (let i = 1; i <= months; i++) {
    const eventDate = `${inDates}-${i.toString().padStart(2, '0')}`;
    const targetEvent = events === null ? event : events.find(event => event.date === eventDate);
    if (targetEvent &&  targetEvent !== undefined) {
      firstTimeOfTargetDate = targetEvent.first_time === '-:-' ? '-:-' : targetEvent.first_time?.split(':').slice(0, 2).join(':');
      lastTimeOfTargetDate = targetEvent.last_time === '-:-' ? '-:-' : targetEvent.last_time?.split(':').slice(0, 2).join(':');
      lateTimeOfTargetDate = targetEvent.late_time === '-:-' ? '-:-' : targetEvent.late_time === null ? '-:-' : targetEvent.late_time;
      userEvents.push(`${firstTimeOfTargetDate === undefined ? `${language === 'uzb' ? lang.uz.author.come : lang.rus.author.come}: -:-` : `${language === 'uzb' ? lang.uz.author.come : lang.rus.author.come}: ${firstTimeOfTargetDate}`} ${lastTimeOfTargetDate === undefined ? `${language === 'uzb' ? lang.uz.author.went : lang.rus.author.went}: -:-` : `${language === 'uzb' ? lang.uz.author.went : lang.rus.author.went}: ${lastTimeOfTargetDate}`} ${lateTimeOfTargetDate === undefined ? `${language === 'uzb' ? lang.uz.author.delay : lang.rus.author.delay}: -:-` : `${language === 'uzb' ? lang.uz.author.delay : lang.rus.author.delay}: ${lateTimeOfTargetDate}`}`);
    } else {
      userEvents.push(
        `${language === 'uzb' ? lang.uz.author.come : lang.rus.author.come}: -:- ${language === 'uzb' ? lang.uz.author.went : lang.rus.author.went}: -:- ${language === 'uzb' ? lang.uz.author.delay : lang.rus.author.delay}: -:-`);
    }

    }

    return createData(
      index + 1,
      <img src={image} alt="User" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />,
      full_name,
      ...userEvents,
      events !== null && events.length > 0 ? events[events?.length - 1]?.total_late : 0
    );
  }) || [];

useEffect(() => {
  setChangeRows(rows)
})

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '100vh', height: '100vh' }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 120px)', height: 'calc(100vh - 120px)' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                sx={columns[columns.length - 1] === column ? {position: "sticky ", right: 0} : columns[0] === column ? {position: "sticky ", left: 0, zIndex: 6} : { textAlign: 'center'}}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
  {columns.map((column) => {
    const value = row[column.id];
    const isLateConditionMet = /(?:Опоздания|Kechikish): -:-/.test(value) || !/Kechikish|Опоздания/.test(value);

    return (
      <TableCell
  sx={
    value === `${language === 'uzb' ? lang.uz.author.come : lang.rus.author.come}: -:- ${language === 'uzb' ? lang.uz.author.went : lang.rus.author.went}: -:- ${language === 'uzb' ? lang.uz.author.delay : lang.rus.author.delay}: -:-`
      ? { textAlign: 'center', backgroundColor: 'rgba(255, 0, 0, 0.5)', borderRight: '1px solid white' }
      : !isLateConditionMet
      ? { textAlign: 'center', backgroundColor: 'rgba(255, 255, 0, 0.7)' }
      : columns[columns.length - 1] === column ? {position: "sticky", right: 0, backgroundColor: 'white'} : columns[0] === column ? {position: "sticky ", left: 0, zIndex: 1, backgroundColor: "white"} :  { textAlign: 'center', backgroundColor: '' }
  }
  key={column.id}
  align={column.align}
>
  {column.format && typeof value === 'number' ? column.format(value) : value}
</TableCell>

    );
  })}
</TableRow>

                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button sx={{position: 'absolute', margin: '7px 0 0 20px', zIndex: 1}} href="/admin" variant="contained" color="inherit">
      <ArrowBackIcon sx={{marginRight: "10px"}}/>
      {language === 'uzb' ? lang.uz.header.adminPage : lang.rus.header.adminPage}
      </Button>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        content='sdwnmfkmwfmlk123456789098765vwbjcbwjcn kwmcvlm,wmvl'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default MonthComponent;