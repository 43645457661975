// styles.js
const styles = {
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 20px',
        backgroundColor: '#007DFF'
    },
    adminHeader: {
      display: 'none'
    },
    headerSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    navInput: {
      m: 1,
      marginRight: '50px',
      "& .MuiInputBase-root": {
        backgroundColor: '#F5F5F5',
      },
      "& .MuiInputBase-input": {
        width: "250px",
        // backgroundColor: '#F5F5F5'
      },
      // "& .MuiInputLabel-root": {
      //   backgroundColor: '#F5F5F5'
      // }
    },
    timePicker: {
      "& .MuiInputBase-root": {
        backgroundColor: '#F5F5F5',
      },
    },
    timeFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#F5F5F5',
        "& .MuiFormControl-root, & .MuiTextField-root": {
          minWidth: "140px",
        },
        "& .css-ewysex-MuiStack-root": {
          padding: 0
        },
        "& .css-1dypyax-MuiStack-root": {
          padding: 0
        }
    },
    genderFilter: {
      display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#F5F5F5'
    },
    timeFilterText: {
        marginRight: '5px',
        color: '#F5F5F5'
    },
    time: {
        width: '100%',
    },
    headerButton: {
      display: 'flex',
      alignItems: 'center',
      zIndex: 1
    },
    smenaButton: {
      marginLeft: '30px'
    },
    demoContainer: {
      "& .MuiTextField-root": {
        width: "140px",
        minWidth: "140px"
      },
    },
    dateContainer: {
      "& .MuiTextField-root": {
        maxWidth: '190px',
      },
    },
    smena: {
       m: 1,
      minWidth: 120,
      "& .MuiInputBase-root": {
        backgroundColor: '#F5F5F5',
      },
    },
  }
  
  export default styles;
  