import Login from "./Components/Login/Login";


function Public () {
    return (

     <Login/>
    )

}

export default Public;