export const lang = {
    uz:{
      header:{
        search:"Global qidiruv",
        smena:"Smena",
        filterText: "Vaqt bo'yicha filter:",
        day: "Kun",
        month: "Oy",
        genderFilter: "Jinsi bo'yicha",
        women: "A",
        men: "E",
        download: "XLS saqlash" ,
        addUsers: "Qo'shish",
        filterFoto: "Rasm bo'yicha",
        homePage: "Bosh sahifa",
        adminPage: "Admin sahifa",
        language: "Til",
      },
  
      author:{
        image:"Rasm",
        fullName:"Ism va Familya",
        phoneNumber:"Telefon nomer",
        gmail:"Elektron pochta",
        position:"Lavozim",
        come: "Keldi",
        went: "Ketdi",
        delay: "Kechikish",
        allDelay: "Umumiy kechikish",
      },

      modal:{
        addUsers: "Xodimni qo'shing",
        status:"Holat",
        AddImage:"Rasm tanlash",
        addButton:"Saqlash",
        active: "Faol",
        vacation: "Dam olish",
        isIll: "Kasal",
        business: "Ish safari",
        employeeCard: "Xodimlar kartasi",
        editImage: "Rasmni o'zgartirish",
        delete: "Oʻchirib tashlamoqchimisiz?",
        yes: "Ha",
        no: "Yo'q",
        editSmenaTime: "Smena vaqtini o'zgartirish",
      },
    },

    rus:{
      header:{
        search:"Глобальный поиск",
        smena:"Смена",
        filterText: "Фильтр по: времени",
        day: "Сегодня",
        month: "За месяц",
        genderFilter: "по полу:",
        women: "Ж",
        men: "М",
        download: "ЭКСПОРТ В XLS" ,
        addUsers: "Добавить",
        filterFoto: "Поиск по фото",
        homePage: "ГЛАВНАЯ СТРАНИЦА",
        adminPage: "СТРАНИЦА АДМИНИСТРАТОРА",
        language: "Язык",
      },
  
      author:{
        image:"	Фото",
        fullName:"Фамилия и имя",
        phoneNumber:"Номер телефона",
        gmail:"Электронная почта",
        position:"Должность",
        come: "Пришел",
        went: "Ушел",
        delay: "Опоздания",
        allDelay: "Всего oпоздани",
      },

      modal:{
        addUsers: "Добавить сотрудника",
        status:"Статус",
        AddImage:"ВЫБРАТЬ ФОТО",
        addButton:"СОХРАНИТЬ",
        active: "Активен",
        vacation: "Отпуск",
        isIll: "Болен",
        business: "Командировка",
        employeeCard: "Карточка сотрудника",
        editImage: "ИЗМЕНИТЬ ИЗОБРАЖЕНИЕ",
        delete: "Вы хотите удалить ?",
        yes: "Да",
        no: "Нет",
        editSmenaTime: "Изменение времени смены",
      },
    },
  
  }