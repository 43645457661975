import { Route, Routes } from "react-router-dom";
import Public from "./Public";
import DayPage from "./Pages/DayPage.js/DayPage";
import AdminPage from "./Pages/AdminPage/AdminPage";
import MonthPage from "./Pages/MonthPage/MonthPage";


function App() {
  const token = localStorage.getItem('token');
  if(token) {
    return (
      <Routes>
        <Route path="" element={<DayPage/>} />
        <Route path="/admin/*" element={<AdminPage/>} />
        <Route path="/month/*" element={<MonthPage/>} />
      </Routes>
    )
  }
  return <Public/>
}

export default App;
