import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import api from '../Api/Api';
import ModalClose from '@mui/joy/ModalClose';
import { lang } from '../../lang/lang';


const AddUsersModal = ({ addUsersModal, setAddUsersModal }) => {
  const language = localStorage.getItem('language');
  const [values, setValues] = useState({
    full_name: '',
    phone_number: '',
    email: '',
    status: '',
    gender: 'true',
    shift: '',
    their_reason: '',
    image: null,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
      
  };

  const handleImageChange = (event) => {
    setValues({
      ...values,
      image: event.target.files[0],
    });
  };

  const handleClose = () => {
    setAddUsersModal(false);
  };

  const addPupil = async () => {
    try {
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }

      const response = await api.post(
        `/Workers/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log(response.data);
      setAddUsersModal(false);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 400) {
      setErrors({ ...errors, email: ["Please include an '@' in the email address"] });
    }
      console.error(error);
    }
  };

  return (
    <Modal
      open={addUsersModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          textAlign: 'center',
          bgcolor: 'background.paper',
          border: '1px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
        {language === 'uzb' ? lang.uz.modal.addUsers : lang.rus.modal.addUsers}
        </Typography>
        <ModalClose onClick={handleClose} variant="plain" sx={{ m: 1 }} />
        {values.image && (
          <img
            src={
              values.image instanceof File
                ? URL.createObjectURL(values.image)
                : values.image
            }
            alt="Selected"
            style={{ width: '100px', marginTop: '10px', objectFit: 'cover' }}
          />
        )}
        <Box sx={{ marginTop: '20px' }} direction="row" spacing={2}>
          <TextField
            sx={{ width: '100%', marginBottom: '20px' }}
            id="full_name"
            size="small"
            name="full_name"
            label={language === 'uzb' ? lang.uz.author.fullName : lang.rus.author.fullName}
            variant="outlined"
            onChange={handleChange}
          />

          <PhoneInput
            id="phone_number"
            placeholder='Номер телефона'
            name="phone_number"
            onChange={(phone, data, event) => handleChange({
              target: { name: "phone_number", value: phone }
            })}
            country="uz"
            style={{ marginBottom: '20px', height: '40px' }}
            inputClass="form-control"
            containerClass="react-tel-input"
            inputStyle={{ width: '100%', height: "100%" }}
          />

<TextField
            sx={{ width: '100%', marginBottom: '20px' }}
            id="email"
            size="small"
            name="email"
            label={language === 'uzb' ? lang.uz.author.gmail : lang.rus.author.gmail}
            variant="outlined"
            onChange={handleChange}
            value={values.email}
            error={errors.email && true}
            helperText={errors.email ? "Please include an '@' in the email address" : ''}
          />

          <TextField
            sx={{ width: '100%', marginBottom: '20px' }}
            id="status"
            size="small"
            name="status"
            label={language === 'uzb' ? lang.uz.author.position : lang.rus.author.position}
            variant="outlined"
            onChange={handleChange}
          />
<Box sx={{display: "flex", alignItems: 'center', justifyContent: 'space-between', "& .MuiFormControl-root": {margin: 0}}}>
<FormControl sx={{m: 1,minWidth: 120}} size="small">
      <InputLabel id="demo-select-small-label">{language === 'uzb' ? lang.uz.header.smena : lang.rus.header.smena}</InputLabel>
      <Select
      autoWidth
        labelId="demo-select-small-label"
        id="demo-select-small"
        label="Смена"
        value={values.shift}
  onChange={(event) => {
    handleChange(event);
    setValues({ ...values, shift: event.target.value });
  }}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
      </Select>
    </FormControl>

    <FormControl sx={{m: 1,minWidth: 120, maxWidth: 120}} size="small">
      <InputLabel id="demo-select-small-label">{language === 'uzb' ? lang.uz.modal.status : lang.rus.modal.status}</InputLabel>
      <Select
      autoWidth
        labelId="demo-select-small-label"
        id="demo-select-small"
        label="Смена"
        value={values.their_reason}
  onChange={(event) => {
    handleChange(event);
    setValues({ ...values, their_reason: event.target.value });
  }}
      >
        <MenuItem value='READY'>{language === 'uzb' ? lang.uz.modal.active : lang.rus.modal.active}</MenuItem>
        <MenuItem value='HOLIDAY'>{language === 'uzb' ? lang.uz.modal.vacation : lang.rus.modal.vacation}</MenuItem>
        <MenuItem value='SICK'>{language === 'uzb' ? lang.uz.modal.isIll : lang.rus.modal.isIll}</MenuItem>
        <MenuItem value='BUSINESS_TRIP'>{language === 'uzb' ? lang.uz.modal.business : lang.rus.modal.business}</MenuItem>
      </Select>
    </FormControl>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="gender"
              value={values.gender}
              onChange={handleChange}
              sx={{ flexDirection: 'row' }}
            >
              <FormControlLabel value="true" control={<Radio />} label={language === 'uzb' ? lang.uz.header.men : lang.rus.header.men} />
              <FormControlLabel value="false" control={<Radio />} label={language === 'uzb' ? lang.uz.header.women : lang.rus.header.women} />
            </RadioGroup>
          </FormControl>
</Box>
          <Button
            sx={{ width: '100%', margin: '10px 0' }}
            variant="contained"
            component="label"
          >
            {language === 'uzb' ? lang.uz.modal.AddImage : lang.rus.modal.AddImage}
            <input
              type="file"
              hidden
              onChange={handleImageChange}
            />
          </Button>
          <Button
            sx={{ width: '100%' }}
            onClick={addPupil}
            variant="contained"
            color="success"
          >
            {language === 'uzb' ? lang.uz.modal.addButton : lang.rus.modal.addButton}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUsersModal;
